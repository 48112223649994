import {React, useEffect, useState} from 'react';
import axios from "axios";
import { Navigate, Link } from "react-router-dom"
// import { Table, Nav ,Spinner, Modal, Button, Form} from 'react-bootstrap'
import Moment from 'moment';
import config from '../config'
// import { Link } from 'react-router';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCoffee, faEdit } from '@fortawesome/free-solid-svg-icons'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import '../AppNew.css';


//  for icons
// npm i --save @fortawesome/fontawesome-svg-core
// npm install --save @fortawesome/free-solid-svg-icons
// npm install --save @fortawesome/react-fontawesome

 

const UserHomeNew = (props) => {

    const [sattaListCity_yearly,sattaListCity_yearlyList] = useState([]);
    const [yearDate,setyearDate] = useState('2024');
    const [FetchData,setFetchData] = useState('resultA');
    

    const [isLoading,setisLoading] = useState(true);
    const [sattaList, setSattaList] = useState([]);
    const [sattaListCity, setSattaListCity] = useState([]);
    const [sattaListCity_last, setSattaListCity_last] = useState([]);
    

    const [siteTitle,setSiteTitle] = useState('Nva Savera');
    const [siteAnnouncement,setSiteAnnouncement] = useState('');

    const [siteTitle2,setSiteTitle2] = useState('');
    const [siteAnnouncement2,setSiteAnnouncement2] = useState('');


    const [siteTitle3,setSiteTitle3] = useState('');
    const [siteAnnouncement3,setSiteAnnouncement3] = useState('');

    const [setDailyRecords1,setDailyRecords] = useState([]);
    
    



    

    function getNowDateTime() {
        const today = new Date();
        const month = today.toLocaleString('default', { month: 'long' });
        

        const year = today.getFullYear();
        const date = today.getDate();


        const analogue = today.toLocaleString('en-US', { hour: 'numeric',minute: 'numeric', hour12: true });
       // const min =  today.toLocaleString('en-US', { minutes: 'numeric', hour12: true });
        

        return `${month}`;
      }


    function getNowTime() {
        const today = new Date();
        const month = today.toLocaleString('default', { month: 'long' });
        

        const year = today.getFullYear();
        const date = today.getDate();


        const analogue = today.toLocaleString('en-US', { hour: 'numeric',minute: 'numeric', hour12: true });
       // const min =  today.toLocaleString('en-US', { minutes: 'numeric', hour12: true });
        

        return `${date}, ${year} ${analogue}`;
      }
      



    const [adminno,setadminno] = useState('');
    let ab = 0;

    // const URL = 'http://localhost:3003'
    // const URL = 'https://satta-backend.herokuapp.com'
    // const URL = 'https://44.207.30.122:8000'
    const URL = config.apiUrl
    
    const [nsdate,setNsdate] = useState('');

    // const month = nsdate.date.toLocaleString("en-us", { month: "long" });
    // const day = nsdate.date.toLocaleString("en-us", { day: "2-digit" });
    // const year = nsdate.date.getFullYear();
    //const month = Moment(nsdate).toLocaleString('en-us',{month:'long', year:'numeric'})

    const today = new Date(nsdate); // yyyy-mm-dd

// Getting short month name (e.g. "Oct")
const month = today.toLocaleString('default', { month: 'long' });
const year = today.toLocaleString('default', { year: 'numeric' });
 
const last_mon = new Date(today.setMonth(today.getMonth() - 1)); 

const last_month = last_mon.toLocaleString('default', { month: 'long' });
const last_year = last_mon.toLocaleString('default', { year: 'numeric' });



     const getAnnounement = async () => {
        let token = localStorage.getItem('loginToken')
        // setisLoading(true)
        const headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
            'secret-key': '#1234nirmaljeetdeveloper987654321#satta#free#react#**project123456789#'
            }
            await axios.get(URL+'/api/announcment',{
                 headers
            }).then((data) => { 
            // setisLoading(false)
               // console.log(data);
                let rs = data.data;
                setSiteTitle(rs.title || 'Nva Savera')
                setSiteAnnouncement(rs.description || '')

                setSiteTitle2(rs.title2 || 'Nva Savera')
                setSiteAnnouncement2(rs.description2 || '')

                setSiteTitle3(rs.title3 || 'Nva Savera')
                setSiteAnnouncement3(rs.description3 || '')

                 

                setadminno(rs.adminno || '')
            }).catch((er) => {
                if (er.response.status == 401) {
                   // console.log('getting eror ');
                   // console.log(er.response.status);
                }
            })
        }
  
     

    const getAnnounementListDaily = async () => {
        //  let token = localStorage.getItem('loginToken')
          // setisLoading(true)
          const headers = {
              'Content-Type': 'application/json;charset=UTF-8',
              "Access-Control-Allow-Origin": "*",
              'secret-key': '#1234nirmaljeetdeveloper987654321#satta#free#react#**project123456789#'
              }
              await axios.get(URL+'/api/get/daily/records',{
                   headers
              }).then((data) => { 
              // setisLoading(false)
             // console.log("==========PARM=========");
               
          
                 let rs = data.data;
                
                 let listolds = [];

              

                  listolds.push({ name: "Mahakaal", 'old': rs[0].resultN, 'new': rs[1].resultN, 'time': "01:00 PM" })
                  listolds.push({ name: "Savera", 'old': rs[0].resultF, 'new': rs[1].resultF, 'time': "02:30 PM" })

                  listolds.push({ name: "Nva Savera", 'old': rs[0].resultE, 'new': rs[1].resultE, 'time': "02:30 PM",'is_full':"Yes" })

                 
                // listolds.push({ name: "Super king", 'old': rs[0].resultG, 'new': rs[1].resultG, 'time': "04:30 PM" })
//                listolds.push({ name: "Jai Luxmi", 'old': rs[0].resultL, 'new': rs[1].resultL, 'time': "04:50 PM" })

                  listolds.push({ name: "Shree ganesh", 'old': rs[0].resultH, 'new': rs[1].resultH, 'time': "04:40 PM" })
                  listolds.push({ name: "Delhi Bajar", 'old': rs[0].resultK, 'new': rs[1].resultK, 'time': "03:10 PM" })

                  listolds.push({ name: "Kuber", 'old': rs[0].resultP, 'new': rs[1].resultP, 'time': "06:00 PM"  })
                  listolds.push({ name: "Faridabad", 'old': rs[0].resultB, 'new': rs[1].resultB, 'time': "06:10 PM" })

                
                  listolds.push({ name: "Punjab Day", 'old': rs[0].resultJ, 'new': rs[1].resultJ, 'time': "05:10 PM" })
                  listolds.push({ name: "New faridabad", 'old': rs[0].resultI, 'new': rs[1].resultI, 'time': "06:40 PM" })

                  listolds.push({ name: "Gaziyabad", 'old': rs[0].resultC, 'new': rs[1].resultC, 'time': "09:00 PM" })

                 // listolds.push({ name: "Himalaya", 'old': rs[0].resultO, 'new': rs[1].resultO, 'time': "06:20 PM" })
                 // listolds.push({ name: "Himachal", 'old': rs[0].resultM, 'new': rs[1].resultM, 'time': "07:10 PM" })
               
                  
                  listolds.push({ name: "Gali", 'old': rs[0].resultD, 'new': rs[1].resultD, 'time': "12:00 PM" })

                 
                  listolds.push({ name: "Disawer", 'old': rs[0].resultA, 'new': rs[1].resultA, 'time': "05:00 AM",'is_full':"Yes" })

                

            // listolds.push({name:"",'old':"",'new':"",'time':""})
  
 
            //console.log(listolds);
                   const vips = listolds;

                  
               setDailyRecords(vips);
               
                

              }).catch((er) => {
                  if (er.response.status == 401) {
                    
                     // console.log('getting eror ');
                     // console.log(er.response.status);
                  }
              })
          }
           

      


        
 

  const getList = async (d = nsdate) => {
    let token = localStorage.getItem('loginToken')
    setisLoading(true)
    // 'Content-Type': 'application/json',
    const headers = {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
        'secret-key': '#1234nirmaljeetdeveloper987654321#satta#free#react#**project123456789#'
      }
     // console.log(d);
      if(!d || d === undefined)
      {
          d = makeTime()
      }
      await axios.get(URL+'/api/get?date='+d,{
          headers
      }).then((data) => { 
        setisLoading(false)
         // console.log(data.data);
          
        //   setSattaList(data.data)
          let sattaListData = [];
            // let startdate = new Date();
            let month = 0;
            // "2022-06-18T18:52:34.064Z"
            
            let startdate = Moment(d+'T10:00:00.000Z','YYYY-MM-DD hh:mm A'); 
           // console.log('startdate ===== >',startdate);
            for (let i = 0; i < 28; i++) {
                const findDate = filterData(data.data,startdate,'YYYY-MM-DD hh:mm A');
                if(findDate)
                {
                   // console.log(i,findDate);
                    sattaListData.push(findDate);
                }
                else
                {
                    const element = {
                        _id : '',
                        resultA : '',
                        resultB : '',
                        resultC : '',
                        resultDate : startdate,
                        resultDateTime : '',
                        createdAt : '',
                        updatedAt : '',
                    };
                    sattaListData.push(element);
                }
                startdate = Moment(startdate, "YYYY-MM-DD hh:mm A").add(20, 'minutes');
            }
            setSattaList(sattaListData)
          getListCities(d)
      }).catch((er) => {
        setisLoading(false)
         // console.log(er);
          if (er.response.status == 401) {
             // console.log('getting eror ');
             // console.log(er.response.status);
          }
      })
  }
  const filterData = (arr,search,format = 'YYYY-MM-DD') => {
      for (let i = 0; i < arr.length; i++) {
        //  // console.log(Moment(arr[i].resultDate).format('YYYY-MM-DD') +" == "+ Moment(search).format('YYYY-MM-DD'));
        if(Moment(arr[i].resultDate).format(format) == Moment(search).format(format))
        {
           // console.log('UTC ==>  ',arr[i].resultDate);
           // console.log('match ==>  ',Moment(arr[i].resultDate).format(format));
              
              return arr[i];
          }
      }
      return false;
  }
  const getListCities = async (d) => {
    let token = localStorage.getItem('loginToken')
    setisLoading(true)
    // 'Content-Type': 'application/json',
    const headers = {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
        'secret-key': '#1234nirmaljeetdeveloper987654321#satta#free#react#**project123456789#'
      }
     // console.log(d);
      if(d === undefined)
      {
          d = makeTime()
      }
      let newMonth = Moment(d).format('YYYY-MM')


      var ProData = "2023-09";
      await axios.get(URL+'/api/city/get?date='+ProData,{
        headers
    }).then((data) => {
      setisLoading(false)
          //// console.log(' ======================================================================');
          //// console.log('city');
          //// console.log(data.data.length);
          //// console.log(data.data);
          //// console.log(' ======================================================================');
          // let cityData = data.data;
          let cityData = [];
          // let startdate = data.data[data.data.length - 1].resultDate || new Date();
          let month = 0;
          // let startdate = newMonth+'-01T'
          let startdate = Moment(ProData+'-01T','YYYY-MM-DD'); 
          for (let i = 0; i <= 31; i++) {
              // let element;
              //// console.log('date starting ==> '+Moment(startdate).format('YYYY-MM-DD'));
              //// console.log('month == ',month);
              if(month == 0)
              {
                  month = Moment(startdate).format('MM');
                  //// console.log('month ==in if === ',month);
              }
              if(month != Moment(startdate).format('MM'))
              {
                  //// console.log('month == break',month);
                  break;
              }
              //// console.log('date entered ==> '+Moment(startdate).format('YYYY-MM-DD'));
              //// console.log('find data ==> '+Moment(startdate).format('YYYY-MM-DD'),filterData(data.data,startdate))
              //// console.log('find data ==> ',filterData(data.data,startdate))
              const findDate = filterData(data.data,startdate);
              if(findDate)
              {
                  //// console.log(i,findDate);
                  cityData.push(findDate);
              }
              else
              {
                  const element = {
                       _id : '',
                       resultA : '',
                       resultB : '',
                       resultC : '',
                       resultD : '',
                       resultE : '',
                       resultF : '',
                       resultDate : startdate,
                       resultDateTime : '',
                       createdAt : '',
                       updatedAt : '',
                   };

                   cityData.push(element);
              }
              startdate = Moment(startdate, "DD-MM-YYYY").add(1, 'days');
              // }
              // startdate = Moment(startdate, "DD-MM-YYYY").add(1, 'days');
          }
          setSattaListCity_last(cityData)
    }).catch((er) => {
       // console.log('city data === >  ',er);
        setisLoading(false)
        if(er.response)
        {
            if (er.response.status == 401) {
               // console.log('getting eror ');
               // console.log(er.response.status);
            }
        }
    })



      await axios.get(URL+'/api/city/get?date='+newMonth,{
          headers
      }).then((data) => {
        setisLoading(false)
            //// console.log(' ======================================================================');
            //// console.log('city');
            //// console.log(data.data.length);
            //// console.log(data.data);
            //// console.log(' ======================================================================');
            // let cityData = data.data;
            let cityData = [];
            // let startdate = data.data[data.data.length - 1].resultDate || new Date();
            let month = 0;
            // let startdate = newMonth+'-01T'
            let startdate = Moment(newMonth+'-01T','YYYY-MM-DD'); 
            for (let i = 0; i <= 31; i++) {
                // let element;
                //// console.log('date starting ==> '+Moment(startdate).format('YYYY-MM-DD'));
                //// console.log('month == ',month);
                if(month == 0)
                {
                    month = Moment(startdate).format('MM');
                    //// console.log('month ==in if === ',month);
                }
                if(month != Moment(startdate).format('MM'))
                {
                    //// console.log('month == break',month);
                    break;
                }
                //// console.log('date entered ==> '+Moment(startdate).format('YYYY-MM-DD'));
                //// console.log('find data ==> '+Moment(startdate).format('YYYY-MM-DD'),filterData(data.data,startdate))
                //// console.log('find data ==> ',filterData(data.data,startdate))
                const findDate = filterData(data.data,startdate);
                if(findDate)
                {
                    //// console.log(i,findDate);
                    cityData.push(findDate);
                }
                else
                {
                    const element = {
                         _id : '',
                         resultA : '',
                         resultB : '',
                         resultC : '',
                         resultD : '',
                         resultE : '',
                         resultF : '',
                         resultDate : startdate,
                         resultDateTime : '',
                         createdAt : '',
                         updatedAt : '',
                     };
 
                     cityData.push(element);
                }
                startdate = Moment(startdate, "DD-MM-YYYY").add(1, 'days');
                // }
                // startdate = Moment(startdate, "DD-MM-YYYY").add(1, 'days');
            }
          setSattaListCity(cityData)
      }).catch((er) => {
         // console.log('city data === >  ',er);
          setisLoading(false)
          if(er.response)
          {
              if (er.response.status == 401) {
                 // console.log('getting eror ');
                 // console.log(er.response.status);
              }
          }
      })
  }


  const getAnnounementListYearly = async (date = yearDate,column=FetchData) => {
    //  let token = localStorage.getItem('loginToken')
      // setisLoading(true)
      const headers = {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
          'secret-key': '#1234nirmaljeetdeveloper987654321#satta#free#react#**project123456789#'
          }
           await axios.get(URL+'/api/city/yearly/get?date='+date+'&column='+column+'',{
               headers
          }).then((data) => { 
          // setisLoading(false)
         // console.log("==========PARM=========");
          
             let rs = data.data;

      
             sattaListCity_yearlyList(rs);


          }).catch((er) => {
              if (er.response.status == 401) {
                
             
              }
          })
      }


        const numbers = [];
      
        for (let i = 1; i <= 31; i++) {
          numbers.push(i);
        }




  useEffect(() => {
    getAnnounement();
    getAnnounementListDaily();
    setNsdate(makeTime())
    getAnnounementListYearly();
    //// console.log(Moment()._d);
    // if(nsdate !== undefined)
    // {
        // return false;
    // }
    getList();
  },[])

  const mystyle = {
             
    backgroundColor: "white",
   
  };

  const handleClick = () => {
    // Fetch the value of the input field
  
    getAnnounementListYearly(yearDate, FetchData);

  };







  const makeTime = () =>{
    const date = new Date();
   // console.log('moment date', Moment(date).format('YYYY-MM'))
    return Moment(date).format('YYYY-MM-DD')
    let year = date.getFullYear();
    let month = date.getMonth()
    let day = date.getDate()
    month++;
    month = (month < 10) ? '0'+month :month;
    day = (day < 10) ? '0'+day : day
    //// console.log(year+'-'+month+'-'+day);
    return year+'-'+month+'-'+day
  }
  return (
      <>
  
      <div className="container-fluid saraData">
        <div className="home-banner">
            <header className="header">
              
                <Link to="/">   <div className="companyName">
        <img src="images/logo.png" alt="Company Logo" />
        <h1>nvasaverasatta<span>.com</span></h1>
</div>      </Link> 
                <div className="social-links ms-auto">
                    <div className="social-content">
                        <i className="icon-whatsapp"></i>
                        <div className="link-text">
                            <div className="chatOn greenTxt">Chat On</div>
                            {/* <div className="appTxt">What's App</div> */}
                        </div>
                    </div>
                    <div className="social-content">
                        <i className="icon-phone"></i>
                        <div className="link-text">
                            {/* <div className="chatOn orangeTxt">CALL US NOW</div> */}
                            <div className="appTxt"><a href="tel:+919914700000">9914700000</a></div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="banner-content">
                <div className="row">
                    <div className="col-md-6 kingTxt">
                        <img src="images/king.png" />
                    </div>
                    <div className="col-md-6 lady-img">
                        <img src="images/lady.png" />
                    </div>
                </div>
            </div>
            <img src="/images/banner-bg.png" />
        </div>
        <div className="sattaOption">
            <div className="circle-img">
                <div className="sattaOptDate">
                    <div className="div">
                        <h6>{getNowDateTime()}</h6>
                        <span>{getNowTime()}</span>
                    </div>
                </div>
                {/* {console.log("=========",window.location.origin+"/images/circle.png")} */}
                <img src="/images/circle.png" />
            </div>
            <div className="sattaPlan">
                <div className="row">
                    <div className="col-md-4">
                        <div className="sattaPlantxt">
                            <h5>{ siteTitle }</h5>
                            <span>{ siteAnnouncement }</span>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="sattaPlantxt">
                            <h5>{ siteTitle2 }</h5>
                            <span>{ siteAnnouncement2 }</span>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="sattaPlantxt">
                            <h5>{ siteTitle3 }</h5>
                            <span>{ siteAnnouncement3 }</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="whatAppCol">
            <div>
                <p>गली हो या Ranchi देसावर जोड़ी मिलेगी सिंगल  कंपनी लाखों लोगों ने ज्वाइन की हुई है जो की काफी सालो से जुड़े है हमारे साथ हर महीने 40 से 50 लाख का प्रॉफिट होता है हमारे भाईयों का गेम पास का प्रूफ देखने केलिए  <span>WhatApp</span> करें <span><a href="tel:+919914700000">9914700000</a></span></p>
                <div className="action-btn"><a href="https://wa.me/919914700000" className="whatapp-btn"><i className="icon-whatsapp"></i>What's App Chat</a><a href="tel:+919914700000" className="call-btn"><i className="icon-phone"></i>Call Now</a></div>
            </div>
        </div>


        <div className="kingResultCol">
           <div className="resultHeading">
                <img className="curveLeft" src="images/border-bg.png" />
                <div className="headingTxt">Nva Savera Satta King Result</div>
                <img className="curveRight" src="images/border-bg-right.png" />
           </div>
           <div className="resultBoxCol">
                <div className="row">


            { 
                
                setDailyRecords1.map((ls,i) => {
                                        ab = i + 1;
                                        if(ls.name !=="")  {

                                            if(ls.is_full=="Yes"){



                                                return (

                                                

                                                    <div className='col-xs-12 col-sm-12 col-md-12 SpecialBox'>
                                <div className='resultBox lght-blue'>
                                    <h2>{ls.name.toUpperCase()}</h2>
                                    <div className='timeTxt'>({ls.time})</div>
                                    <div className='totalCount'>
                                        <div className='minTxt'>{ls.old}</div>
                                        <img src='images/new-bg.png' />
                                        <div className='maxTxt'>{ls.new ?? "-"}</div>
                                    </div>
                                </div>
                            </div> )

                                            }else{

                                        return (

                                            <div className='col-xs-6 col-sm-6 col-md-6'>
                        <div className='resultBox lght-blue'>
                            <h2>{ls.name.toUpperCase()}</h2>
                            <div className='timeTxt'>({ls.time})</div>
                            <div className='totalCount'>
                                <div className='minTxt'>{ls.old}</div>
                                <img src='images/new-bg.png' />
                                <div className='maxTxt'>{ls.new ?? "-"}</div>
                            </div>
                        </div>
                    </div> )
                                
                                }
                            
                            }else{
                                        return  <div className='col-xs-6 col-sm-6 col-md-6'>j</div>
                                    } 
                                }
                                    )}  


                     
                   
                </div>
           </div>
        </div>

        <div className="bg-white p-3">

       
            <div className='selectDate'>
            <Link to="/satta-king-result" > 
            <button className='call-btn nnamecenter'>Satta King Result</button>   
             </Link>
            </div>
         
            <div className="selectDate">
                <label>Select Date</label>
                
                <div className="cal-input">
                    {/* <i className="icon-calendar"></i> */}
                <input type="date" className="form-control "
                                value={ nsdate }
                            onChange={(e) => {
                                setNsdate(e.target.value)
                               // console.log(Moment(e.target.value).format('YYYY-MM'));
                               // console.log(e.target.value) 
                                getList(e.target.value)
                            }}/>
                {/* <input type="text" value="21-11-2023" readonly></input> */}
                </div>
            </div>
            <div className="recordTableCol">
                <div className="heading-container">
               <div className="recordHeading greenBg"><span>{month.toUpperCase()} RECORD CHART {year.toUpperCase()}</span></div>
                </div>
                <div className="chartTable">
                          <table className="table table-striped">
                              <thead>
                                  <tr>
                                      <th>Date</th>
                                      <th>Mahakaal<span>(01:00 PM)</span></th>
                                      <th>Nva Savera<span>(02:30 PM)</span></th>
                                      <th>Savera<span>(02:30 PM)</span></th>
                                      <th>Delhi Bajar<span>(03:10 PM)</span></th>
                                      {/* <th>Super king<span>(4:30 PM)</span></th> */}
                                      <th>Shree ganesh<span>(04:40 PM)</span></th>
                                      {/* <th>Jai Luxmi<span>(04:50 PM)</span></th> */}
                                     

                                  </tr>
                              </thead>
                              <tbody>


                                  {sattaListCity.map((ls, i) => {
                                      ab = i + 1;
                                      return (
                                          <tr key={i}>
                                              {/* <td>{ i + 1}</td> */}
                                              {/* <td>{ ls.title }</td> */}
                                              {/* <td>{ ls.description }</td> */}
                                              <td className='ns-city-table hight'>{(!ls.resultDate) ? '-' : Moment(ls.resultDate).format('DD')}</td>
 
                                              <td className='columns-ns'>{ls.resultN} {/*Mahakaal*/}</td> 
                                             
                                              <td className='columns-ns'>{ls.resultE}{/* Nva Savera   */}</td>
                                              <td className='columns-ns'>{ls.resultF}{/* Savera  */}</td>
                                              <td className='columns-ns'>{ls.resultK}{/* Delhi Bajar  */}</td>
                                             {/* <td className='columns-ns'>{ls.resultG}  {Super king} </td>  */}
                                              <td className='columns-ns'>{ls.resultH}{/* Shree ganesh  */}</td>
                                              {/* <td className='columns-ns'>{ls.resultL}{Jai Luxmi }</td> */}
                                            



                                              {/* <td>
                                                <Link to={'/edit/'+ls._id}>
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </Link>
                                            </td> */}
                                          </tr>

                                      )
                                  })}

                              </tbody>
                          </table>



<table align='center'>

    <tr><td className='centerData'>
        <h3>Your money is ready!!</h3>
<h3>Play And win</h3>

<h3><p class="orangeTxt">ऑनलाइन प्ले  करने के लिए संपर्क करें (10-950) <span><br></br><a href="tel:+919914700000">9914700000 👈👈</a></span></p>
</h3>
 

<h3>अपनी घड़ी के परिणाम dalne के लिए संपर्क करें</h3></td></tr>
</table>

                          <table className="table table-striped">
                              <thead>
                                  <tr>
                                      <th>Date</th>
                                      
                                      
                                      <th>Punjab Day<span>(05:10 PM)</span></th>
                                      <th>Fridabad<span>(06:10 PM)</span></th>
                                      {/* <th>Himalaya<span>(06:20 PM)</span></th> */}
                                      {/* <th>Himachal<span>(07:10 PM)</span></th> */}
                                      <th>Kuber<span>(06:00 PM)</span></th>
                                      <th>New faridabad<span>(07:20 PM)</span></th>
                                      <th>Gaziyabad<span>(09:00 PM)</span></th>
                                      <th>Gali <span>(12:00 PM)</span></th>
                                      <th>Disawer<span>(05:00 AM)</span></th>
                                   
                                    

                                  </tr>
                              </thead>
                              <tbody>


                                  {sattaListCity.map((ls, i) => {
                                      ab = i + 1;
                                      return (
                                          <tr key={i}>
                                              {/* <td>{ i + 1}</td> */}
                                              {/* <td>{ ls.title }</td> */}
                                              {/* <td>{ ls.description }</td> */}
                                              <td className='ns-city-table hight'>{(!ls.resultDate) ? '-' : Moment(ls.resultDate).format('DD')}</td>

                                              <td className='columns-ns'>{ls.resultJ}{/* Punjab Day  */}</td>
                                            
                                              <td className='columns-ns'>{ls.resultB} {/* Fridabad  */} </td>
                                             {/* <td className='columns-ns'>{ls.resultO}  { Himalaya  } </td>  */}
                                             
                                              {/* <td className='columns-ns'>{ls.resultM}{  Himachal  }</td> */}
                                             
                                              <td className='columns-ns'>{ls.resultP}{/* Kuber  */}</td>
                                              <td className='columns-ns'>{ls.resultI}{/* New faridabad  */}</td>
                                              <td className='columns-ns'>{ls.resultC} {/* Gaziyabad  */}</td>
                                              <td className='columns-ns'>{ls.resultD}{/* Gali  */}</td>
                                              <td className='columns-ns'>{ls.resultA}{/* Disawer  */}</td>
                                             
                                            



                                              {/* <td>
                                                <Link to={'/edit/'+ls._id}>
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </Link>
                                            </td> */}
                                          </tr>

                                      )
                                  })}

                              </tbody>
                          </table>

                   

                    
                </div>
            </div>


            <div className="recordTableCol mt-5 d-none">
                <div className="heading-container">
                    <div className="recordHeading orangeBg"><span>JANUARY RECORD CHART 2024</span></div>
                </div>
                <div className="chartTable">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Nva Savera<span>(02:30 PM)</span></th>
                                <th>Savera<span>(02:40 PM)</span></th>
                                {/* <th>Super king<span>(4:20 PM)</span></th> */}
                                <th>Shree ganesh<span>(04:45 PM)</span></th>
                                <th>Fridabad<span>(06:10 PM)</span></th>
                                <th>New faridabad<span>(07:00 PM)</span></th>
                                <th>Gaziyabad<span>(09:00 PM)</span></th>
                                <th>Gali <span>(12:00 AM)</span></th>
                                <th>Disawer<span>(05:00 AM)</span></th>
                            </tr>
                        </thead>
                        <tbody>
                           
                        { sattaListCity_last.map((ls,i) => {
                                        ab = i + 1;
                                        return (
                                        <tr key={i}>
                                            {/* <td>{ i + 1}</td> */}
                                            {/* <td>{ ls.title }</td> */}
                                            {/* <td>{ ls.description }</td> */}
                                            <td className='ns-city-table hight'>{ (!ls.resultDate ) ? '-' : Moment(ls.resultDate).format('DD') }</td>
                                            <td className='columns-ns'>{ ls.resultE }{/* Nva Savera   */}</td>
                                            <td className='columns-ns'>{ ls.resultF }{/* Savera  */}</td>
                                            {/* <td className='columns-ns'>{ ls.resultG }{Super king  }</td> */}
                                            <td className='columns-ns'>{ ls.resultH }{/* Shree ganesh  */}</td>
                                            <td className='columns-ns'>{ ls.resultB } {/* Fridabad  */} </td>
                                            <td className='columns-ns'>{ ls.resultI }{/* New faridabad  */}</td>
                                            <td className='columns-ns'>{ ls.resultC } {/* Gaziyabad  */}</td>
                                            <td className='columns-ns'>{ ls.resultD }{/* Gali  */}</td>
                                            <td className='columns-ns'>{ ls.resultA }{/* Disawer  */}</td>
                                            {/* <td>
                                                <Link to={'/edit/'+ls._id}>
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </Link>
                                            </td> */}
                                        </tr>

                                        )
                                    }) }

                        </tbody>
                    </table>
                </div>
            </div>



 



            <div className="recordTableCol mt-5 chartTableData">
    <div className="heading-container">
           
                    <div className="recordHeading orangeBg"><span>YEARLY CHART</span></div>
               
  
    <div className="bg-white p-3">


 
  <div className="row">
    <div className="col-md-4">
      <div className="mb-3">
        <label>Select Date</label>
        <input
          type="text"
          className="form-control"
          value={yearDate}
          onChange={(e) => setyearDate(e.target.value)}
          
        />
      </div>
    </div>

    <div className="col-md-4">
      <div className="mb-3">
        <label>Select Result</label>
        <select
          className="form-control"
          onChange={(e) => setFetchData(e.target.value)}
          
        >

<option value="resultN">Mahakaal</option>
<option value="resultF">Savera</option>
          <option value="resultE">Nva Savera</option>
          <option value="resultK">Delhi Bajar</option>
          {/* <option value="resultG">Super king</option> */}
          <option value="resultH">Shree ganesh</option>
          {/* <option value="resultO">Himalaya</option> */}
          {/* <option value="resultM">Himachal</option> */}
          {/* <option value="resultL">Jai Luxmi</option> */}
          <option value="resultJ">Punjab Day</option>
          <option value="resultP">Kuber</option>
          <option value="resultB">Fridabad</option>
          <option value="resultI">New faridabad</option>
          <option value="resultC">Gaziyabad</option>
          <option value="resultD">Gali</option>
          <option value="resultA">Disawer</option>

        </select>
      </div>
    </div>
    <div className="col-md-4">
      <div className="mb-3">
       
        <button
        onClick={handleClick}
         
          className='btn btn-success mt-4'>Fetch Results</button>
         

      </div>
    </div>
  </div>
</div>


    </div>
    <div className="chartTable">
        <table className="table table-striped">
            <thead>
                <tr>
                    <th>{sattaListCity_yearly.year}</th>
                    { sattaListCity_yearly?.monthlyData?.map((ls,i) => {
                          
                            return (
                        
                            <th className='ns-city-table hight'>{ ls.monthName }</th>
                        
                        )
                        }) }
                   
                </tr>
  {numbers.map((number) => (
          <tr style={mystyle} key={number}>

<td>{number}</td>
{/* {console.log(sattaListCity_yearly[number])} */}
 
{ sattaListCity_yearly?.monthlyData?.map((ls,i) => {
                          
                          const valuesArray = ls.data[number]?.[FetchData];

                  
                        
                          
                            return (

                                <td className='ns-city-table hight'>{  valuesArray }</td>
                              
                            )
                         

                          
                      }) }


          </tr>

        ))}

 

        

            </thead>
            <tbody>
               
          

            </tbody>
        </table>
    </div>
</div>

            <div className="warningBox">
                <img src="images/warning-bg.png" />
                <div className="warningContent">
                    <div className="warningHead">चेतावनी (WARNING)</div>
                    <div className="warningTxt">यह साइट सिर्फ और सिर्फ मनोरंजन के लिए बनाई गई है । यह साइट सट्टे से जुड़ी किसी भी गतिविधि को बढ़ावा नहीं देती । सट्टा और जुआं जिस देश या राज्य में प्रतिबंधित है वहां के लोग हमारी साइट को ब्लॉक कर दें | किसी भी लाभ या हानि के लिए आप खुद जिम्मदार होंगे ।</div>
                </div>
            </div>

            <div className="whatAppCol border-bottom pb-5">
                <div>
                    <p>सभी सट्टा खेलने वाले भाईयो के लिए खुश खबरी गली; दिसावर; गाजियाबाद ;और PUNE RANCHI DELHI GOLD में होगा सिंगल जोड़ी ब्लास्ट तो जिन भाईयो को लाखो का लॉस है होगा अब एक ही बार में कवर तो बिना समय निकालें जल्दी से जल्दी वॉट्सएप पर जुड़े और कमाए लाखो रुपए</p>
                    <div className="callTo">
                        <p >गेम पास का प्रूफ देखने केलिए WhatApp करें</p>
                        <p className="orangeTxt">सुमन गुप्ता <span><a href="tel:+919914700000">9914700000</a></span></p>
                    </div>
                    <div className="action-btn"><a href="https://wa.me/919914700000" className="whatapp-btn"><i className="icon-whatsapp"></i>What's App Chat</a><a href="#" className="call-btn"><i className="icon-phone"></i>Call Now</a></div>
                </div>
            </div>


            <div className="recordTableCol mt-5">
                <div className="heading-container">
                    <div className="recordHeading blackBg"><span>Shree Balaji LOTTERY GAME</span></div>
                </div>
                <div className="chartTable">
                    <table className="table table-striped">
                    <thead>
                                    <tr>
                                    <th className='ns-city-table hight ns-result'>#</th>
                                    {/* <th className='ns-city-table'>Title</th> */}
                                    {/* <th className='ns-city-table'>Description</th> */}
                                    <th className='ns-city-table columns-ns'>Date Time</th>
                                    <th className='ns-city-table columns-ns'>Result A</th>
                                    <th className='ns-city-table columns-ns'>Result B</th>
                                    <th className='ns-city-table columns-ns'>Result C</th>
                                    {/* <th className='ns-city-table'>Action</th> */}
                                    </tr>
                                </thead>
                        <tbody>
                        { sattaList.map((ls,i) => {
                                        return (
                                        <tr key={i}>
                                            <td className='ns-city-table hight ns-result'>{ i + 1}</td>
                                            {/* <td>{ ls.title }</td> */}
                                            {/* <td>{ ls.description }</td> */}
                                            <td className='columns-ns-resultls'>{ (!ls.resultDate) ? '-' : Moment(ls.resultDate).format('hh:mm A') }</td>
                                            <td className='columns-ns-resultls'>{ ls.resultA }</td>
                                            <td className='columns-ns-resultls'>{ ls.resultB }</td>
                                            <td className='columns-ns-resultls'>{ ls.resultC }</td>
                                            {/* <td>
                                                <Link to={'/edit/'+ls._id}>
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </Link>
                                            </td> */}
                                        </tr>

                                        )
                                    }) }
                        </tbody>
                    </table>
                </div>
            </div>


            <div className="whatNva">
                <div className="thinkman-img"><img src="images/think-man.png" /></div>
                
                <div className="blackPatti">
                    <img src="images/question-mark.png" />
                    <h3>What is Nva Savera Satta ?</h3>
                    <p>Satta King is a kind of lottery game based on numbers from 00 to 99 which comes under "Gambling". The real name of the game is Satta King, in which "Satta" means betting or gambling, and "Nva Savera" means a pot through which a number is drawn out. In the Nva Savera Satta game, people wager money on their chosen numbers from between 00 to 99. After which, a number is drawn out of the pot. Whichever person's number was drawn out, he would win the prize and people called him the Nva Savera Satta. It's not the name of the game, it was the title used to honor the winner of the Satta Matka. But as this game became popular, people started knowing it by the name of Nva Savera Satta.</p>
                 
                </div>
            </div>

        </div>

    </div>

      </>
  );
}

export default UserHomeNew;
